/* DetailBlog.module.css */
    @font-face {
        font-family: PlusJakartaSans;
        src: url(/public/font/PlusJakartaSans.ttf);
        font-display: swap;
    }
    .content {
        line-height: 1.6;
        color: #333;
        font-family: PlusJakartaSans; 
    }
  
    .content h1 {
        font-size: 3.75rem;
        font-weight: 500;
        line-height: 1;
        /* margin-top: 1em; */
        margin-bottom: 1em;
        /* border: 1px solid #000; */
    }

    .content h2 {
        font-size: 3rem;
        font-weight: 500;
        line-height: 1;
        margin-top: 1em;
        margin-bottom: 1em;
        /* border: 1px solid #000; */
    }
    .content h3 {
        font-size: 2.25rem;
        font-weight: 500;
        line-height: 1;
        margin-top: 1em;
        margin-bottom: 1em;
        /* border: 1px solid #000; */
    }
    
    .content p {
        font-size: 1.25rem;
        line-height: 2.25rem; 
        font-weight: 500; 
        margin-bottom: 1em;
        color: #64748B;
        text-align: justify;

        /* font-style: normal; 
        font-variant-caps: normal; 
        font-weight: 400; 
        letter-spacing: normal; 
        orphans: auto; 
        text-align: start; 
        text-indent: 0px; 
        text-transform: none; 
        white-space: normal; 
        widows: auto; 
        word-spacing: 0px; 
        -webkit-text-stroke-width: 0px; 
        text-decoration: none; 
        caret-color: rgb(0, 0, 0); color: rgb(0, 0, 0); */
        /* margin-top: 1em; */
    }

    .content figcaption {
        font-size: 1rem;
        line-height: 1.75rem; 
        font-weight: 500; 
        margin-bottom: 1em;
        color: #64748B;
        text-align: center;
    }
    

    /* TABLE */
    .content table {
        
        margin: 0;
        padding: 0;
        width: 100%;
        
    }
    .content table th {
        border: 1px solid #000;
        text-align: left;
        height: 10px !important;
    }
    .content table td {
        border: 1px solid #000;
        text-align: left;

    }

    /* SUMMARY */
    /* Style untuk elemen details */
    .content details {
        margin: 20px 0;
        padding: 10px;
        background-color: #f9fafb; /* Light background */
        border: 1px solid #e2e8f0; /* Border light */
        border-radius: 8px; /* Rounded corners */
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Soft shadow */
        transition: all 0.3s ease;
    }
    .content details summary {
        font-size: 1.25rem;
        line-height: 1.75rem; 
        font-weight: 500; 
        margin-bottom: 1em;
        color: #64748B;
      
        cursor: pointer;
        padding: 10px;
        margin: 0;
        background-color: #ffffff; /* White background */
        border: 1px solid #e2e8f0; /* Border around summary */
        border-radius: 5px;
        transition: background-color 0.3s ease, color 0.3s ease;
    }
    /* LIST ORDER */
    .content ul{
        list-style-type: disc;
    }
    /* LIST UNORDER */
    .content ol{
        list-style-type: decimal;
    }
    
    /* HYPERLINK */
    .content a {
        color: #E63946;
    }
  

    @media (min-width: 320px) and (max-width: 767px) {
        .content h1 {
            font-size: 2rem;
            font-weight: 500;
            line-height: 1.25;
            margin-bottom: 1em;
            /* border: 1px solid #000; */
        }
    
        .content h2 {
            font-size: 1.5rem;
            font-weight: 500;
            line-height: 1.25;
            margin-top: 1em;
            margin-bottom: 1em;
            /* border: 1px solid #000; */
        }
        .content h3 {
            font-size: 1rem;
            font-weight: 500;
            line-height: 1;
            margin-top: 1em;
            margin-bottom: 1em;
            /* border: 1px solid #000; */
        }
        .content p {
            font-size: 1rem;
            text-align: justify;
            line-height: 1.75rem; 
            font-weight: 500; 
            margin-bottom: 1em;
            color: #64748B;
            /* margin-top: 1em; */
        }
    }
    